const Hamburger = $('.hamburger')
const Categories = $('[data-categories]')

const openCategories = () => {
    Hamburger.addClass('open')
    Categories.fadeIn("fast", () => {
        Categories.addClass('active')
    })
}

const closeCategories = () => {
    Hamburger.removeClass('open')
    Categories.fadeOut("fast", () => {
        Categories.removeClass('active')
    })
}

$('[data-type="toggle-menu"]').click(e => {
    e.stopPropagation()
    if (Categories.hasClass('active')) {
        closeCategories()
    } else {
        openCategories()
    }
});

let ParentCategory = $("[data-parent-category]")

ParentCategory.mouseenter(function() {
    if ($(window).width() <= 991) return
    clearTimeout(window.menuDalay)
    let this_obj = $(this)
    window.menuDalay = setTimeout(() => {
        let id = this_obj.data('id')
        ParentCategory.removeClass('active')
        this_obj.addClass('active')
        $(`[data-subcategories]`).addClass('d-none')
        $(`[data-subcategories][data-parent-category-id="${id}"]`).removeClass('d-none')
    }, 200)
})

ParentCategory.mouseleave(() => {
    clearTimeout(window.menuDalay)
})

$(window).click(() => {
    closeCategories()
})

Categories.click(e => {
    e.stopPropagation()
})
